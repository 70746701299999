import { WEBSOCKETS_URL } from "@/misc/constants";
import { Selectors as AuthSelectors } from "@/store/auth/selectors";
import { Selectors as UserSelectors } from "@/store/user/selectors";
import { Actions as MessengerActions } from "@/store/messenger/slice";
import { Effects as MessengerEffects } from "@/store/messenger/effects";
import { Actions as NotifActions } from "@/store/eventNotifications/slice";
import { Actions as PremiumActions } from "@/store/premium/slice";
import { Actions as ExtMsgSubActions } from "@/store/extMessengerSub/slice";
import ee from "@/utils/ee";
import {
  NEW_REVIEW,
  PREMIUM_PAID,
  CLIENT_INVITE_ACCEPTED,
  PREMIUM_SMS_ACC_GIFT,
  NEW_MESSENGER_SUB,
  PREMIUM_EXPIRED,
  PREMIUM_EXPIRED_LAST_PHASE,
} from "@/misc/notificationTypes";
import { getUserSettings } from "@/misc/user-settings";
import { messageSound, notifSound } from "@/misc/sounds";
import { mastersCache, portfolioCache } from "@/misc/httpCache";
import { CLIENT_INVITE_REJECTED } from "../misc/notificationTypes";
import { queryClient } from "../network/queryClient";
import { QueryKeys } from "../network/queryKeys";
import { TasksCacheService } from "../services/tasks/cache";
import { Wildcard } from "phx-wildcard";
import { WarehouseProductMutationHandler } from "@/services/warehouse/products/mutationHandler";
import { WarehouseProductCategoryMutationHandler } from "@/services/warehouse/productCategories/mutationHandler";
import { WarehouseTxnMutationHandler } from "@/services/warehouse/txns/mutationHandler";
import { SaloonInviteMutationHandler } from "@/services/saloonInvites/mutationHandler";
import { SaloonStaffMutationHandler } from "@/services/saloonStaffService/mutationHandler";
import { SaloonMutationHandler } from "@/services/saloons/mutationHandler";
import { MasterServiceMutationHandler } from "@/services/masterServices/mutationHandler";
import { MasterDiscountMutationHandler } from "@/services/masterDiscounts/mutationHandler";
import { ClientMutationHandler } from "@/services/clients/mutationHandler";
import { TaskMutationHandler } from "@/services/tasks/mutationHandler";
import { FreeSlotsMutationHandler } from "@/services/freeSlots/mutationHandler";
import { SmsAccountMutationHandler } from "@/services/smsAccount/mutationHandler";

class Websocket {
  get isConnected() {
    return (
      this.socket?.conn?.readyState === 1 &&
      this.userChannel?.state === "joined"
    );
  }
  async init() {
    this.store = window.__reduxStore;
    const state = this.store.getState();
    const token = AuthSelectors.getSocketToken(state);
    const userId = UserSelectors.getUserId(state);
    const Phoenix = await import("phoenix");
    const socket = new Phoenix.Socket(WEBSOCKETS_URL, {
      params: {
        token,
      },
    });
    socket.connect();
    this.userChannel = socket.channel(`user:${userId}`);
    this.userChannel
      .join()
      .receive("ok", () => {
        console.log(`connected to user ${userId} channel`);
      })
      .receive("error", (reason) => {
        console.log(`unable to connect user ${userId} channel`, reason);
      })
      .receive("timeout", () => {
        console.log("Networking issue. Waiting...");
      });
    socket.onOpen(() => console.log("websocket connection opened"));
    socket.onError((e) => console.error("websocket error", e));
    socket.onClose(() => console.log("websocket connection closed"));
    this.wc = new Wildcard(this.userChannel);
    this.socket = socket;
    this._initListeners();
  }
  _initListeners() {
    const channel = this.userChannel;
    const store = this.store;
    this.wc.on("notification/*", (_event, payload) => {
      const { meta } = payload;
      const { notif_sounds } = getUserSettings();
      if (notif_sounds) {
        notifSound.play();
      }

      store.dispatch(NotifActions.addFromSocket(payload));
      if (payload.type === NEW_REVIEW) {
        TasksCacheService.invalidateAll();
        mastersCache.reset();
      } else if (
        payload.type === PREMIUM_PAID ||
        payload.type === PREMIUM_SMS_ACC_GIFT
      ) {
        store.dispatch(PremiumActions.setPremium(meta));
      } else if (payload.type === PREMIUM_EXPIRED_LAST_PHASE) {
        mastersCache.reset();
        portfolioCache.reset();
      } else if (payload.type === PREMIUM_EXPIRED) {
        store.dispatch(
          PremiumActions.setPremium({
            expires: null,
            allow_trial: false,
            trial: false,
          })
        );
      } else if (
        payload.type === CLIENT_INVITE_ACCEPTED ||
        payload.type === CLIENT_INVITE_REJECTED
      ) {
        queryClient.invalidateQueries(QueryKeys.clients);
        queryClient.invalidateQueries(QueryKeys.clientsInvites);
      } else if (payload.type === NEW_MESSENGER_SUB) {
        queryClient.invalidateQueries(QueryKeys.clientMessengerSub);
      }
    });
    channel.on("ext_msg/self_sub", (sub) => {
      store.dispatch(ExtMsgSubActions.setData(sub));
    });
    channel.on("message/new", (message) => {
      store.dispatch(MessengerEffects.addMessageFromSocket(message));
      const { message_sounds } = getUserSettings();
      if (!window.location.pathname.endsWith("messages") && message_sounds) {
        messageSound.play();
      }
    });
    channel.on("message/received", (payload) => {
      if (window.location.pathname.endsWith("messages")) {
        store.dispatch(
          MessengerActions.updateMessage({
            dialog: payload.dialog,
            message: payload.message,
            updates: {
              received: true,
            },
          })
        );
      }
    });
    channel.on("message/remove", (payload) => {
      store.dispatch(
        MessengerActions.removeMessage({
          dialog: payload.dialog,
          _id: payload.message,
        })
      );
    });
    channel.on("achieve/new", (achieve) => {
      ee.emit("new_achieve", achieve);
    });
    channel.on("data_sync", ({ op: operation, data }) => {
      switch (operation) {
        case "warehouse_product_category_created":
          return WarehouseProductCategoryMutationHandler.categoryCreated(data);
        case "warehouse_product_category_updated":
          return WarehouseProductCategoryMutationHandler.categoryUpdated(data);
        case "warehouse_product_category_removed":
          return WarehouseProductCategoryMutationHandler.categoryRemoved(data);
        case "warehouse_product_created":
          return WarehouseProductMutationHandler.productCreated(data);
        case "warehouse_product_updated":
          return WarehouseProductMutationHandler.productUpdated(data);
        case "warehouse_product_removed":
          return WarehouseProductMutationHandler.productRemoved(data);
        case "warehouse_txn_created":
          return WarehouseTxnMutationHandler.txnCreated(data);
        case "warehouse_txn_updated":
          return WarehouseTxnMutationHandler.txnUpdated(data);
        case "warehouse_txn_removed":
          return WarehouseTxnMutationHandler.txnRemoved(data);
        case "warehouse_txn_cancelled":
          return WarehouseTxnMutationHandler.txnCancelled(data);
        case "saloon_invite_accepted":
        case "saloon_invite_rejected":
          return SaloonInviteMutationHandler.statusChanged(data);
        case "saloon_invite_removed":
          return SaloonInviteMutationHandler.removed(data);
        case "saloon_master_removed":
          return SaloonStaffMutationHandler.masterRemoved(data);
        case "saloon_admin_removed":
          return SaloonStaffMutationHandler.adminRemoved(data);
        case "saloon_updated":
          return SaloonMutationHandler.updated(data);
        case "saloon_base_schedule_updated":
          return SaloonMutationHandler.baseScheduleUpdated(data);
        case "saloon_removed":
          return SaloonMutationHandler.removed(data);
        case "service_created":
          return MasterServiceMutationHandler.serviceCreated(data);
        case "service_updated":
          return MasterServiceMutationHandler.serviceUpdated(data);
        case "service_removed":
          return MasterServiceMutationHandler.serviceRemoved(data);
        case "master_discount_created":
          return MasterDiscountMutationHandler.discountCreated(data);
        case "master_discount_updated":
          return MasterDiscountMutationHandler.discountUpdated(data);
        case "master_discount_removed":
          return MasterDiscountMutationHandler.discountRemoved(data);
        case "client_created":
          return ClientMutationHandler.clientCreated(data);
        case "client_updated":
          return ClientMutationHandler.clientUpdated(data);
        case "client_removed":
          return ClientMutationHandler.clientRemoved(data);
        case "client_stats_changed":
          return ClientMutationHandler.clientStatsChanged(data);
        case "task_created":
          return TaskMutationHandler.taskCreated(data);
        case "task_updated":
          return TaskMutationHandler.taskUpdated(data);
        case "task_cancelled":
          return TaskMutationHandler.taskCancelled(data);
        case "task_removed":
          return TaskMutationHandler.taskRemoved(data);
        case "task_accepted":
          return TaskMutationHandler.taskAccepted(data);
        case "task_completed":
          return TaskMutationHandler.taskCompleted(data);
        case "task_note_updated":
          return TaskMutationHandler.taskNoteUpdated(data);
        case "task_paid":
          return TaskMutationHandler.taskPaid(data);
        case "task_photo_requested":
          return TaskMutationHandler.taskPhotoRequested(data);
        case "day_slots_buckets_created":
          return FreeSlotsMutationHandler.daySlotsBucketsCreated(data);
        case "sms_account_updated":
          return SmsAccountMutationHandler.accountUpdated(data);
        default:
          return 1;
      }
    });
  }
  execIfNotConnected(fn) {
    if (!this.isConnected) return fn();
  }
  destroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
    if (this.wc && !this.wc.isDestroyed) {
      this.wc.destroy();
    }
    this.userChannel = undefined;
  }
}

export default new Websocket();
