import React, { useState, useEffect, useCallback } from "react";
import PT from "prop-types";
import omit from "lodash/omit";
import View from "./view";
import { useHistory } from "react-router-dom";
import { useNotify } from "@/hooks/useNotify";
import { MASTER_SEARCH_RADIUS } from "@/misc/constants";
import { useSearchUrlGenerator } from "@/hooks/useSearchUrlGenerator";
import { GeoService } from "@/services/geo";
import { useUserId } from "@/hooks/useUserId";
import { useLocation } from "react-router-dom";

const Form = (props) => {
  const history = useHistory();
  const { pathname: urlPathname } = useLocation();
  const { notifyError } = useNotify();
  const [service, setService] = useState("");
  const [location, setLocation] = useState({
    coords: null,
    label: "",
  });
  const isAuth = !!useUserId();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isAuth && urlPathname === "/") {
        GeoService.detectCurrentLocation()
          .then(({ lat, lng, city, name, fullName }) => {
            const loc = {
              coords: { lat, lng },
              label: name || city || fullName,
            };
            setLocation(loc);
          })
          .catch((e) => console.error(e));
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [isAuth, urlPathname]);
  const generateUrl = useSearchUrlGenerator();
  useEffect(() => {
    setService(
      props.query.service ? decodeURIComponent(props.query.service) : ""
    );
  }, [props.query.service]);
  useEffect(() => {
    setLocation({
      label: props.name,
      coords: props.coords,
    });
  }, [props.coords, props.name]);
  const onServiceChange = useCallback((service) => {
    setService(service);
  }, []);
  const onLocationChange = useCallback(
    async (input) => {
      setLocation({
        coords: input ? location.coords : null,
        label: input,
      });
    },
    [location]
  );
  const onGeoClick = async () => {
    try {
      const { lat, lng, city, name, fullName } =
        await GeoService.detectCurrentLocation();
      const loc = {
        coords: { lat, lng },
        label: name || city || fullName,
      };
      setLocation(loc);
      props.onLocationChange(loc);
    } catch (e) {
      notifyError(props.t("errors:geolocation_failed"));
    }
  };
  const onLocationSelect = useCallback((data) => {
    const loc = {
      label: data.name || data.fullName,
      coords: { lat: data.lat, lng: data.lng },
    };
    setLocation(loc);
    props.onLocationChange(loc);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    const url = generateUrl({
      params: props.matchedPath?.params,
      query: {
        ...props.query,
        ...(location.coords
          ? props.query
          : omit(props.query, ["lat", "lng", "address_label"])),
        service: encodeURIComponent(service),
        ...location.coords,
        address_label: location.label,
        radius: props.query.radius || MASTER_SEARCH_RADIUS,
      },
    });
    history.push(url);
  };
  return (
    <View
      t={props.t}
      onSubmit={onSubmit}
      onServiceChange={onServiceChange}
      service={service}
      onLocationSelect={onLocationSelect}
      preloadSearchPage={props.preloadSearchPage}
      onLocationChange={onLocationChange}
      location={location}
      onGeoClick={onGeoClick}
    />
  );
};
Form.propTypes = {
  coords: PT.object,
  name: PT.string,
  query: PT.object,
  matchedPath: PT.object,

  preloadSearchPage: PT.func.isRequired,
  onLocationChange: PT.func.isRequired,
};

export default Form;
