import React from "react";
import s from "styled-components";
import { useTheme } from "@/hooks/useTheme";
import { useLocation } from "react-router-dom";

const StyledLogo = s.img`
  width: 120px;
  height: auto;
`;

const appIconUrl = "/svg/logo-lakki-crm";
const marketplaceIconUrl = "/svg/logo-lakki-marketplace";

const Logo = (props) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDark = theme.__name__ === "dark";
  let iconUrl;
  if (["/app", "/master", "/salon"].some((p) => pathname.startsWith(p))) {
    iconUrl = appIconUrl;
  } else {
    iconUrl = marketplaceIconUrl;
  }
  if (isDark) {
    iconUrl += "-dark";
  }
  return <StyledLogo src={iconUrl + ".svg"} {...props} />;
};

export default Logo;
