import { get } from "./index";
import { appConfigCache } from "@/misc/httpCache";
import type { tAppConfig, tAccountingConfig, tAppStoryRoot } from "@/types";
import type { AxiosResponse } from "axios";
const BASE = "https://lakki-app.com/serve/cfg";

const sharedHttpClientCfg = {
  withCredentials: false,
  headers: { "Cache-control": "no-cache" },
};
const getAppConfig = async () => {
  const res: AxiosResponse<tAppConfig> = await get(`${BASE}/shared.json`, {
    cache: appConfigCache,
    ...sharedHttpClientCfg,
  });
  return res.data;
};
const getAccountingConfig = async () => {
  const res: AxiosResponse<tAccountingConfig> = await get(
    `${BASE}/accounting.json`,
    {
      cache: false,
      ...sharedHttpClientCfg,
    }
  );
  return res.data;
};
const getAppStories = async () => {
  const res: AxiosResponse<tAppStoryRoot> = await get(
    `${BASE}/app_stories.json`,
    {
      cache: false,
      ...sharedHttpClientCfg,
    }
  );
  return res.data;
};

export const ConfigAPI = {
  getAccountingConfig,
  getAppConfig,
  getAppStories,
};
